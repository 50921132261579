import React, { useState } from "react";
import { Form, Dropdown } from "react-bootstrap";

import "./CustomDropDown.css";

export const CustomDropDown = ({
  itemText,
  optionsList,
  selectedOption,
  placeholderTitle,
  onSelect,
  theme,
  customMenu,
  menuCanOpen,
  smallText,
  hideFilter = false,
  loadMoreCallback,
  showLoadmoreButton = false,
  isLoading = false,
  buttontext
}) => {
  const handleLoadMore = () => {
    if (showLoadmoreButton) {
      loadMoreCallback();
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/"
      className={`dropdown-toggle-btn ${theme === "light" ? "dropdown-toggle-btn-light" : "text-white"
        }`}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {menuCanOpen ? <span>&#x25bc;</span> : ""}
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [filter, setFilter] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={`${className} dropdown-menu-${theme} w-100`}
          aria-labelledby={labeledBy}
        >
          {hideFilter ? (
            <></>
          ) : (
            <Form.Control
              autoFocus
              className={`mx-3 my-2 dropdown-filter-input-${theme}`}
              placeholder="Type to filter..."
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            />
          )}
          <ul className="list-unstyled dropdown-item-list">
            {React.Children.toArray(children)
              .filter(
                (child) =>
                  !filter ||
                  (typeof child.props.children === "string" &&
                    child.props.children
                      .toLowerCase()
                      .includes(filter.toLowerCase()))
              )
              .sort((a, b) => {
                const nameA = a.props.children.toLowerCase();
                const nameB = b.props.children.toLowerCase();
                return nameA.localeCompare(nameB);
              })}
          </ul>
        </div>
      );
    }
  );

  // Function to check if a string starts with a number
  const startsWithNumber = (str) => /^\d/.test(str);

  // Separate the entries into those starting with numbers and those starting with letters
  const numberFirst = optionsList
    ? optionsList.filter(option => startsWithNumber(option.label))
    : [];
  const alphabetFirst = optionsList
    ? optionsList.filter(option => !startsWithNumber(option.label))
    : [];

  // Sort the alphabetically sorted list
  const sortedAlphabetFirst = alphabetFirst.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  // Combine the lists: numbers first, then alphabetically sorted
  const sortedOptionsList = numberFirst.concat(sortedAlphabetFirst);

  return (
    <Dropdown
      className={`${smallText ? "text-xl" : "text-2xl"} rounded ${!menuCanOpen ? "border-0" : ""
        }`}
      onSelect={onSelect}
      disabled
    // disabled={true}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        className={`dropdown-btn-${theme} dropdown-toggle-btn d-flex align-items-center`}
      >
        {selectedOption ? (
          <span className="w-100 dropdown-toggle-btn-text">
            {selectedOption.label}
          </span>
        ) : (
          <span
            className="w-100 dropdown-toggle-btn-text"
            style={{ color: "#6C757D" }}
          >
            {placeholderTitle}
          </span>
        )}
      </Dropdown.Toggle>
      {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "150px" }}
        >
          <i className="fas fa-spinner  text-4xl fa-spin  mx-auto text-white" />
        </div>
      ) : (
        menuCanOpen && (
          <Dropdown.Menu as={customMenu ? CustomMenu : "div"} className="w-100">
            {sortedOptionsList.length > 0 ? (
              sortedOptionsList.map((option) => (
                <Dropdown.Item
                  key={option.id}
                  className={`dropdown-item-${theme}`}
                  eventKey={option.key ? option.key : option.id}
                  id={option.id}
                  active={selectedOption && option.id === selectedOption.id}
                >
                  {option.label}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.ItemText className="text-white">
                {itemText}
              </Dropdown.ItemText>
            )}
            {showLoadmoreButton &&
              optionsList &&
              optionsList.length > 0 &&
              buttontext && (
                <button
                  type="button"
                  style={{ marginLeft: "15px" }}
                  onClick={handleLoadMore}
                >
                  {buttontext}
                </button>
              )}
          </Dropdown.Menu>
        )
      )}
    </Dropdown>
  );
};
